<template>
  <div class="ori-banner">
    <div class="inner-pan-wrap">
      <div class="inner-pan">
        <div class="inner-pan-content">
          <div class="box">
            <el-avatar
              :size="50"
              :src="require('@/assets/images/ori/avatar.png')"
            ></el-avatar>
            <div class="r-title">
              <div class="title">Hi.欢迎来到应必客</div>
              <div class="b-btns">
                <el-button type="primary" size="mini">登录</el-button>
                <el-button size="mini">注册</el-button>
              </div>
            </div>
          </div>
          <div class="box">
            <el-image
              style="width: 70px; height: 70px; margin-left: -15px"
              :src="require('@/assets/images/ori/enterprise.png')"
              fit="fill"
            ></el-image>
            <div class="r-content">
              <div class="title">72191</div>
              <div class="desc">数字经济创新企业数</div>
            </div>
          </div>
          <div class="box">
            <el-image
              style="width: 70px; height: 70px; margin-left: -15px"
              :src="require('@/assets/images/ori/money.png')"
              fit="fill"
            ></el-image>
            <div class="r-content">
              <div class="title">322,776,736</div>
              <div class="desc">成果转化需求总额（元）</div>
            </div>
          </div>
          <div class="box">
            <img
              :src="require('@/assets/images/ori/home_lable.png')"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>

    <el-carousel height="510px" :autoplay="true">
      <el-carousel-item v-for="(item, index) in imgs" :key="index">
        <img height="510px" width="100%" :src="item" alt="" srcset="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "ori-banner",
  data() {
    return {
      imgs: [
        require("@/assets/images/ori/202106241438322719.jpg"),
        require("@/assets/images/ori/202108251033421024.png"),
        require("@/assets/images/ori/202107022241077697.png"),
        require("@/assets/images/ori/202108251033421024.png"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  ::v-deep .el-carousel {
    min-width: 1920px;
  }

  // 内嵌内容
  .inner-pan-wrap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .inner-pan {
      z-index: 9;
      position: absolute;
      height: 510px;
      width: 250px;
      padding: 28px 0;
      // padding-top: 30px;
      // padding-bottom: 20px;
      .inner-pan-content {
        background: #fff;
        height: 100%;
        .box {
          margin: 0 20px;
        }

        .box + .box {
          border-top: 1px dashed #e4e9ef;
        }

        .box:nth-child(1) {
          ::v-deep .el-avatar {
            margin-right: 10px;
          }
          display: flex;
          padding: 28px 0 15px;

          .r-title {
            flex: 1 1 auto;
            .title {
              font-weight: bold;
            }
            .b-btns {
              margin-top: 5px;
            }
          }
        }

        .box:nth-child(2),
        .box:nth-child(3) {
          padding: 23px 0 15px;
          display: flex;

          .r-content {
            flex: 1 1 auto;

            .title {
              font-size: 20px;
              margin: 0;
              margin-top: 5px;
              font-weight: 700;
              color: #e62d31;
            }

            .desc {
              margin-top: 5px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
            }
          }
        }

        .box:nth-child(4) {
          padding-top: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>