<template>
  <div class="ori-header">
    <div class="nav-wrap">
      <div class="left">
        <div class="logo">
          <img :src="require('@/assets/images/in-peak.png')" alt="" srcset="" />
          <div class="title-wrap">
            <div class="title">应必客</div>
            <div class="url">in-peak.com</div>
          </div>
        </div>
        <div class="nav-list">
          <div class="btn" style="margin-left: 15px">
            <el-button
              icon="el-icon-s-promotion"
              size="medium"
              type="primary"
              @click="$router.push('/ori-publish')"
              >发布需求</el-button
            >

            <!-- <el-dropdown>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>发布需求</el-dropdown-item>
                <el-dropdown-item>发布项目</el-dropdown-item>
                <el-dropdown-item>创建简历</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
          <div class="btn" @click="$router.push('/ori-home')">首页</div>

          <div class="btn" @click="$router.push('/ori-result')">技术生态</div>
          <div class="btn" @click="$router.push('/ori-enterprise')">
            产业生态
          </div>
          <div class="btn" @click="$router.push('/ori-job')">人才生态</div>
          <div class="btn" @click="$router.push('/ori-financing')">
            投融资生态
          </div>

          <div class="btn" @click="$router.push('/ori-mall')">商城</div>

          <!-- <div class="btn" @click="toMall()">商城</div> -->

          <div class="btn">
            <el-dropdown>
              <span class="el-dropdown-link">
                关于<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- <div class="btn">
            <el-dropdown>
              <span class="el-dropdown-link">
                解决方案<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </div>
      </div>
      <div class="right">
        <span class="btn" @click="$router.push('/auth')">
          <i class="el-icon-edit-outline"></i>
          注册</span
        >
        <span class="btn" @click="$router.push('/auth')">
          <i class="bi bi-box-arrow-in-right"></i>
          登录</span
        >
      </div>
      <div class="slogan">
        <!-- 产业生态，人才生态平台 -->
        <img :src="require('@/assets/images/slogan.svg')" alt="" />
      </div>
    </div>

    <div class="more-nav-wrap" v-if="false">
      <div class="more-nav" @mouseleave="showNavDetails = false">
        <ul class="nav">
          <li
            :class="{ active: activeRoute === 'oriHome' }"
            @click="$router.push('/ori-home')"
          >
            首页
          </li>

          <template>
            <li>发布</li>

            <li
              @mouseenter="
                showNavDetails = true;
                activeNav = 0;
              "
            >
              企业服务
            </li>
            <li
              @mouseenter="
                showNavDetails = true;
                activeNav = 1;
              "
            >
              投资人服务
            </li>

            <li
              @mouseenter="
                showNavDetails = true;
                activeNav = 2;
              "
            >
              创业者服务
            </li>

            <li>非标管家</li>
            <li>图纸云</li>
            <li>透明工厂</li>
            <li>设备备件云</li>
            <li>寻源中心</li>
            <li>智客</li>
            <li>资讯会展</li>
            <li>搜索自动化部件</li>
          </template>

          <template v-if="false">
            <li
              :class="{ active: activeRoute === 'oriEvent' }"
              @click="$router.push('/ori-event')"
            >
              大赛活动
            </li>
            <li
              :class="{ active: activeRoute === 'oriPerson' }"
              @click="$router.push('/ori-person')"
            >
              人才库
            </li>
            <li
              :class="{ active: activeRoute === 'oriEnterprise' }"
              @click="$router.push('/ori-enterprise')"
            >
              企业库
            </li>
            <li
              :class="{ active: activeRoute === 'oriInvest' }"
              @click="$router.push('/ori-invest')"
            >
              投融资
            </li>
            <li
              :class="{ active: activeRoute === 'oriPolicy' }"
              @click="$router.push('/ori-policy')"
            >
              政策库
            </li>
            <li
              :class="{ active: activeRoute === 'oriResult' }"
              @click="$router.push('/ori-result')"
            >
              成功转化
            </li>

            <li :class="{ active: activeRoute === '' }">产品商城</li>
            <li
              :class="{ active: activeRoute === 'CuCenter' }"
              @click="$router.push('/ori-cucenter')"
            >
              三
            </li>
          </template>
        </ul>

        <!-- 下拉具体明细 -->
        <div
          class="nav-details"
          :class="{ show: showNavDetails && navList[activeNav] }"
        >
          <div class="content" v-if="navList[activeNav]">
            <div class="left-desc">
              <div class="title">{{ navList[activeNav].title }}</div>
              <div class="desc">
                {{ navList[activeNav].desc }}
              </div>
            </div>
            <div class="right-nav">
              <div
                class="btn-link"
                v-for="item in navList[activeNav].items"
                :key="item"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 产业生态，人才生态
export default {
  name: "ori-header",
  computed: {
    activeRoute() {
      return this.$route.name;
    },
  },

  data() {
    return {
      showNavDetails: false,

      activeNav: null,
      navList: [
        {
          title: "技术服务平台",
          desc: "中南地区最大最权威的计量检测公司入驻，打造最专业的首席计量检测平台，同时与长株潭知名高校深度合作，引领技术革新，企业技术从此“不落伍”;",
          items: [
            "企业号",
            "企业点评",
            "ECclub企业项目库",
            "创新平台",
            "创新咨询",
          ],
        },
        {
          title: "产业服务平台",
          desc: "引入深圳证交所路演中心、长沙高新区股权交易中心，上海、深圳股权托管交易中心、证券公司、投资咨询公司等机构，打造高端专业的产业服务平台，让企业上市从此“不是梦”;",
          items: ["LP源计划", "VClub", "VClub投资机构库"],
        },
        {
          title: "人才服务平台",
          desc: "引入长沙海外人",
          items: ["寻求报道"],
        },
      ],
    };
  },

  methods: {
    toMall() {
      window.location.href = "/mall";
    },
  },
};
</script>


<style lang="scss" scoped>
.ori-header {
  position: sticky;
  top: 0;
  z-index: 999;

  background: #fff;
  border-top: 4px solid #2777c6;
  border-bottom: 1px solid #ededed;
  .nav-wrap {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    list-style: none;

    height: 70px;

    .left {
      position: relative;
      display: flex;
      align-items: center;

      .logo {
        margin-right: 15px;
        width: 190px;
        display: flex;
        align-items: center;
        img {
          width: 60px;
        }

        .title-wrap {
          margin-left: 8px;
          text-align: center;
          .title {
            font-size: 40px;
          }

          .url {
            margin-top: -10px;
            font-size: 14px;
            color: #666;
            font-weight: bold;
          }
        }
      }

      .nav-list {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        margin-top: 15px;

        .btn {
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          display: inline-block;
          margin-left: 30px;
          font-weight: bold;
          color: #333;

          &:hover,
          &:active {
            color: #409eff;
          }
        }

        ::v-deep .el-dropdown .el-dropdown-link {
          font-weight: bold;
          font-size: 16px;
          &:hover,
          &:active {
            color: #409eff;
          }
        }
      }
    }

    .right {
      margin-top: 15px;
      display: flex;
      align-items: center;
      .btn {
        cursor: pointer;
        margin-left: 20px;
        font-weight: bold;
        color: #333;
        &:hover,
        &:active {
          color: #409eff;
        }
      }
    }

    .slogan {
      position: absolute;
      top: 0;
      left: 18%;
      font-size: 14px;
      img {
        width: 230px;
      }
    }
  }

  .more-nav-wrap {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ededed;

    .more-nav {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      height: 50px;
      line-height: 50px;

      .nav {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          color: #666;
          margin-right: 30px;

          &:hover,
          &:active {
            color: #409eff;
          }
        }
      }

      // 下拉具体服务
      .nav-details {
        line-height: initial;
        z-index: 100;
        border: 1px solid #ededed;
        background: #fff;
        position: absolute;
        top: 100%;
        transition: all 0.15s ease-in;

        height: 0;
        overflow: hidden;
        border-width: 0;
        opacity: 0;

        &.show {
          opacity: 1;
          height: 230px;
          border-width: 1px;
        }

        .content {
          padding: 20px 25px;
          width: 550px;

          display: flex;
          align-items: flex-start;

          min-height: 230px;

          .left-desc {
            width: 50%;
            padding-right: 15px;

            .title {
              font-weight: bold;
              color: #333;
            }

            .desc {
              margin-top: 10px;
              font-size: 14px;
              color: #666;
            }
          }

          .right-nav {
            width: 50%;
            .btn-link {
              // display: inline-block;
              // width: 50%;
              cursor: pointer;
              padding: 0 10px;
              line-height: 40px;
              height: 40px;
              color: #333;
              font-size: 14px;
              // margin-left: 4px;
              & + .btn-link {
                border-top: 1px solid #ededed;
              }
            }
          }
        }
      }
    }
  }
}
</style>





// 黑色导航
// <style lang="scss" scoped>
// .ori-header {
//   background: #000;
//   color: #fff;
//   height: 45px;
//   line-height: 45px;
//   .nav {
//     width: 1200px;
//     margin: 0 auto;
//     padding-left: 0;
//     display: flex;
//     justify-content: space-between;
//     li {
//       cursor: pointer;
//       display: inline-block;
//       padding: 0 15px;
//       transition: 0.15s;
//       &:hover,
//       &:active,
//       &.active {
//         background: red;
//       }
//     }
//   }
// }
//
</style>