<template>
  <div class="message">
    <div class="shadow-line"></div>
    <div class="message-list">
      <el-scrollbar style="height: 100%">
        <div class="message-list-content">
          <div class="time">15:10</div>

          <div
            class="msg-box"
            v-for="i in 8"
            :key="i"
            :class="{ right: !!(i % 2) }"
          >
            <div class="avatar">
              <el-avatar
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              ></el-avatar>
            </div>

            <div class="msg-content">
              123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456123456
            </div>

            <span class="read-tag"> 已读 </span>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="bottom">
      <div class="tool-bar">
        <span class="btn">
          <i class="el-icon-location-outline"></i>
        </span>
      </div>
      <div class="input-wrap">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="inputValue"
          maxlength="300"
        >
        </el-input>
        <div class="send-btn">
          <span>按Enter键发送，按Ctrl+Enter键换行</span>
          <el-button round size="mini">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils/utils.js";

import {
  // IndiPostWrite,
  // IndiPostInquire,
  // IndiReplyWrite,
  IndiReplyInquire,
  // IndiReplySecondWrite,
} from "@/http/query.js";

const Token = "notoken_notoken";

export default {
  name: "message",

  props: {
    // 发送公司
    fromIndiSocialId: {
      type: String,
      require: true,
    },

    // 发送人
    fromIndiOwnerId: {
      type: String,
      required: true,
    },

    // 接收公司
    toIndiSocialId: {
      type: String,
      required: true,
    },

    // 接收人
    toIndiOwnerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // 需要发送的内容
      inputValue: "",

      // 上传的照片或者文件
      fileName: "",

      // 消息消息列表
      msg: [],

      // 向上滚动时，获取历史记录
      getMorePending: false,

      // 上次获取数据成功的时间戳
      lastSuccessTime: +new Date(
        `${formatTime(new Date()).split(" ")[0]} 00:00:00`
      ),
    };
  },

  methods: {
    // 获取消息列表
    async getMsg() {
      const {
        fromIndiSocialId,
        fromIndiOwnerId,
        toIndiSocialId,
        toIndiOwnerId,
      } = this;

      const now = +new Date(`${formatTime(new Date()).split(" ")[0]} 00:00:00`);

      const next = now + 86400000 - 1;

      const res = await IndiReplyInquire({
        IndiOwnerId: fromIndiOwnerId, // 发送人
        IndiReplyPostid: "", // 内容随机码

        IndiReplyContent: "", // 内容
        IndiMStatus: "", // 内容状态
        IndiSocialAffixId: "", // 附件id
        IndiSocialReplyId: toIndiSocialId, // 接受公司
        IndiReplyId: toIndiOwnerId, // 接收人
        IndiReplyCreatetimeA: String(now).slice(0, 10),
        IndiReplyCreatetimeB: String(next).slice(0, 10),
        IndiSocialId: fromIndiSocialId, // 发送公司
        Token: Token(),
        Model: "40101",
      });

      let hasNews = false;

      if (res) {
        // 记下旧的
        const oldObj = {};
        this.msg.forEach((i) => {
          oldObj[i.IndiReplyPostid] = i.IndiReplyPostid;
        });

        // 检查是否有新消息
        res.forEach((i) => {
          if (!oldObj[i.IndiReplyPostid]) {
            hasNews = true;
          }
        });

        this.msg.unshift(...res);

        const obj = {};
        const datas = [];

        this.msg.forEach((i) => {
          if (!obj[i.IndiReplyPostid]) {
            datas.push(i);
          }

          obj[i.IndiReplyPostid] = i.IndiReplyPostid;
        });

        this.msg = datas;
      }

      // 有新消息才往下滚动
      this.$nextTick(() => {
        try {
          if (hasNews) {
            this.msgScrollWrap.scrollTop = this.msgScrollWrap.scrollHeight;

            // 有新消息上报
            window.top.postMessage("show", "*");
          }
        } catch (error) {
          error;
        }
      });

      console.log(this.$refs["msg-scroll"]);
    },

    /**
     * 如果成功返回且有数据停止循环
     * 返回错误，再次尝试
     * 成功返回，没有数据，继续往后循环 20 次，直到有消息即停止
     */
    async getMoreMsg(e, loopTime = 0) {
      // if (e) return;
      if (this.msgScrollWrap && this.msgScrollWrap.scrollTop) {
        return;
      }

      const { deltaY } = e;
      if (deltaY > 0) return;

      const {
        lastSuccessTime,
        fromIndiSocialId,
        fromIndiOwnerId,
        toIndiSocialId,
        toIndiOwnerId,
      } = this;

      const now = lastSuccessTime - 1;
      const last = now - 86400000;

      if (this.getMorePending) return;
      this.getMorePending = true;

      console.log("获取更多数据", e);

      const res = await IndiReplyInquire({
        IndiOwnerId: fromIndiOwnerId, // 发送人
        IndiReplyPostid: "", // 内容随机码

        IndiReplyContent: "", // 内容
        IndiMStatus: "", // 内容状态
        IndiSocialAffixId: "", // 附件id
        IndiSocialReplyId: toIndiSocialId, // 接受公司
        IndiReplyId: toIndiOwnerId, // 接收人
        IndiReplyCreatetimeA: String(last).slice(0, 10),
        IndiReplyCreatetimeB: String(now).slice(0, 10),
        IndiSocialId: fromIndiSocialId, // 发送公司
        Token: Token(),
        Model: "40101",
      });

      if (res) {
        this.msg.unshift(...res);

        // 去掉重复
        const obj = {};
        const datas = [];

        this.msg.forEach((i) => {
          if (!obj[i.IndiReplyPostid]) {
            datas.push(i);
          }

          obj[i.IndiReplyPostid] = i.IndiReplyPostid;
        });

        this.msg = datas;

        this.lastSuccessTime = last;
      }

      this.getMorePending = false;

      if (res && res.length) {
        return;
      }

      // 如果没有数据，往上继续取 20 天
      if (res && !res.length && loopTime < 20) {
        this.getMoreMsg({ deltaY: -100 }, ++loopTime);
        console.log("loopTime", loopTime);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  border: 1px solid #ddd;
  position: relative;

  .shadow-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: -1px;
    height: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .message-list {
    background: #fff;

    height: 450px;

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    ::v-deep .el-scrollbar__bar.is-vertical {
      width: 6px;
      top: 2px;
    }

    .message-list-content {
      padding: 10px 20px;

      .time {
        text-align: center;
        font-size: 14px;
        color: #999;
      }

      .msg-box {
        display: flex;
        align-items: flex-start;

        margin: 15px 0;

        &.right {
          flex-direction: row-reverse;

          .avatar {
            display: none;
          }

          .msg-content {
            color: #fff;
            background: #409eff;
          }

          .read-tag {
            display: inline-block;
            align-self: flex-end;
            margin: 0 6px;
            font-size: 12px;
            color: #666;
          }
        }

        .avatar {
          margin-right: 15px;
        }

        .read-tag {
          display: none;
        }

        .msg-content {
          font-size: 14px;
          padding: 8px 15px;
          border-radius: 4px;
          background: #f8f8f8;
          margin-top: 4px;
          max-width: 400px;

          word-break: break-all;
        }
      }
    }
  }

  .bottom {
    .tool-bar {
      background: #fff;
      border-top: 1px solid #ededed;
      font-size: 24px;

      height: 40px;
      line-height: 40px;

      .btn {
        margin: 0 20px;

        i {
          color: #666;
        }
      }
    }

    .input-wrap {
      position: relative;
      background: #fff;
      // height: 110px;

      ::v-deep .el-textarea {
        textarea {
          border: none;
        }
      }

      .send-btn {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 15px;
        padding-bottom: 10px;
        font-size: 14px;
        span {
          color: #999;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>