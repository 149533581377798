<template>
  <div id="app">
    <!-- <Header class="header-wrap" /> -->
    <keep-alive>
      <router-view />
    </keep-alive>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Header from "./components/Header.vue";
// import Footer from "./components/Footer.vue";
export default {
  name: "App",
  // components: {
  //   Header,
  //   Footer,
  // },
};
</script>

<style lang="scss" scoped>
* {
  transition: 0.1s;
}

#app {
  // padding-top: 60px;
  .header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .el-link {
    cursor: pointer;
    &:hover,
    &:active {
      color: #fff;
      background: #4fafc2;
    }
  }
}
</style>
