import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 字体图标
import '@/assets/style/iconfont/iconfont.css'

// 最初样式
// import "@/assets/style/origin.css"

// bootstrap
import '@/assets/style/main.scss'

// bs-icons
import '@/assets/style/bs-icons/bootstrap-icons.css'

// element-ui 响应式布局断点隐藏
import 'element-ui/lib/theme-chalk/display.css'

// element-ui
import '@/plugins/element-ui.js'
import '@/assets/style/element.scss'

import 'element-ui/lib/theme-chalk/base.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
Vue.component(CollapseTransition.name, CollapseTransition)

// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '@/assets/style/swiper.css'
Vue.use(VueAwesomeSwiper)

//数字自增
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 自定义组件
import RRow from '@/components/RRow.vue'

Vue.component('r-row', RRow)

import R41 from '@/components/R4-1.vue'
Vue.component('r4-1', R41)

import R31 from '@/components/R3-1.vue'
Vue.component('r3-1', R31)

import R21 from '@/components/R2-1.vue'
Vue.component('r2-1', R21)

//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	/* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
	ak: 'BQ0BuHdyPWSsLpgcdWYnD7pL2KsF6WzA',
})

// vxe-table
import 'xe-utils'
import '@/plugins/vxe-table.js'

// 自定义原始组件
import '@/components/index.js'

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
