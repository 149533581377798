import SnowflakeId from 'snowflake-id'
/**
 * 时间格式化
 */
export const formatTime = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return (
    [year, month, day].map(formatNumber).join('-') +
    ' ' +
    [hour, minute, second].map(formatNumber).join(':')
  )
}

const formatNumber = (n) => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// 生成 id
export const genId = () => new SnowflakeId().generate()

// 验证手机号码
// export const isPhone = (phone) => {
//   return /^[1][3,4,5,7,8][0-9]{9}$/.test(phone)
// }

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
