<template>
  <div class="card-wrap-com">
    <div class="card-wrap-content w1200">
      <div class="head">
        <div class="zh-title-desc">
          <span class="title">{{ zhTitle }}</span>
          <span class="desc">{{ desc }}</span>
        </div>

        <!-- <div class="en-title">
          {{ enTitle }}
        </div> -->

        <span class="more">
          <slot name="more" />
        </span>
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ori-card-wrap",
  props: {
    enTitle: String,
    zhTitle: String,
    desc: String,
  },
};
</script>

<style lang="scss" scoped>
.card-wrap-com {
  background: #f9f9f9;
  padding-top: 30px;
  .card-wrap-content {
    .head {
      position: relative;
      height: 75px;
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .en-title {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.05;
        line-height: 75px;
        font-size: 57px;
        font-weight: 700;
        text-align: left;
        color: #000000;
      }

      .zh-title-desc {
        .title {
          font-size: 24px;
          font-weight: 700;
          color: #333333;
        }
        .desc {
          margin-left: 17px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }

      .more {
        color: #999;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .content {
      margin-top: 20px;
      display: flex;
    }
  }
}
</style>