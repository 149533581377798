import Vue from 'vue'

import oriHeader from './ori-com/Header.vue'
import oriBanner from './ori-com/Banner.vue'
import CardWrap from './ori-com/CardWrap.vue'
import OptionRow from './ori-com/Select/OptionRow.vue'
import MatchFilter from './ori-com/Select/MatchFilter.vue'
import Pagination from './ori-com/Pagination.vue'
import Message from './ori-com/Message.vue'

Vue.component(oriHeader.name, oriHeader)
Vue.component(oriBanner.name, oriBanner)
Vue.component(CardWrap.name, CardWrap)
Vue.component(OptionRow.name, OptionRow)
Vue.component(MatchFilter.name, MatchFilter)
Vue.component(Pagination.name, Pagination)
Vue.component(Message.name, Message)
