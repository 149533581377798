<template>
  <div class="ori-match-filter">
    <div class="filter-wrap w1200">
      <ori-option />
      <ori-option />
    </div>
  </div>
</template>

<script>
export default {
  name: "match-filter",
};
</script>

<style lang="scss" scoped>
.ori-match-filter {
  background: #f9f9f9;
  position: relative;
  padding: 1px 0;
  .filter-wrap {
    margin-top: 30px;
    background: #fff;
    padding: 10px 30px 0;
  }
}
</style>