<template>
  <el-row type="flex" justify="center" align="middle">
    <el-col 
      :xs="22" 
      :sm="20" 
      :md="20" 
      :lg="16"
      :xl="14"
    >
      <slot />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'RRow'
}
</script>

<style>

</style>