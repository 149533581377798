import { gql } from 'graphql-request'
import client, { cloudRun } from './index'
import scryptPwd from '../utils/scryptPwd'

export const signin = async (data = {}) => {
  const res = await client
    .request(
      gql`
        query($IndiMobile: String, $IndiPasswd: String) {
          IndiSocialMemberLogInAll(
            IndiSocialId: ""
            IndiPublicCoding: ""
            IndiLoginMethod: ""
            IndiLoginMethodId: ""
            IndiRegisteredName: ""
            IndiEmail: ""
            IndiQq: ""
            Model: "40101"
            IndiMobile: $IndiMobile
            IndiPasswd: $IndiPasswd
          ) {
            Details {
              IndiArray
              IndiAvatarImg22
              IndiClassId
              IndiEmail
              IndiMobile
              IndiName
              IndiOutside
              IndiOwnerId
              IndiPublicCoding
              IndiQq
              IndiSex
              IndiSharAreaInfo
              IndiSocialDesc
              IndiSocialId
              IndiSort
              IndiTruename
            }
            Token
          }
        }
      `,
      {
        IndiMobile: data.phone,
        // 密码经过工具函数 scryptPwd ，经过了 scrypt 加密
        IndiPasswd: await scryptPwd(data.password),
      }
    )
    .catch(() => null)

  return res
}

// 发送验证码
export const sendSmsCode = async (data = {}) => {
  const res = await cloudRun
    .request(
      gql`
        mutation(
          $IndiSocialId: String
          $IndiOwnerId: String
          $IndiMobile: String!
        ) {
          sendSmsCode(
            IndiSocialId: $IndiSocialId
            IndiOwnerId: $IndiOwnerId
            IndiMobile: $IndiMobile
          )
        }
      `,
      {
        IndiSocialId: data.IndiSocialId,
        IndiOwnerId: data.IndiOwnerId,
        IndiMobile: data.IndiMobile,
      }
    )
    .catch(() => null)

  return res && res.sendSmsCode
}

// 验证验证码
export const checkSmsCode = async (data = {}) => {
  const res = await cloudRun
    .request(
      gql`
        mutation($SmsSign: String!, $SmsCode: String!, $IndiMobile: String!) {
          checkSmsCode(
            SmsSign: $SmsSign
            SmsCode: $SmsCode
            IndiMobile: $IndiMobile
          )
        }
      `,
      {
        SmsSign: data.SmsSign,
        SmsCode: data.SmsCode,
        IndiMobile: data.IndiMobile,
      }
    )
    .catch(() => null)

  return res && res.checkSmsCode
}

export const IndiReplyInquire = async (data = {}) => {
  const $apollo = data.$apollo
  if (!$apollo) return

  const res = await $apollo
    .query({
      query: gql`
        query(
          $IndiOwnerId: String
          $IndiReplyPostid: String
          $IndiReplyCreatetimeB: String
          $IndiReplyContent: String
          $IndiMStatus: String
          $IndiSocialAffixId: String
          $IndiSocialReplyId: String
          $IndiReplyId: String
          $IndiReplyCreatetimeA: String
          $IndiSocialId: String
          $Token: String
          $Model: String
          $IndiReplyTitle: String
        ) {
          IndiReplyInquire(
            IndiOwnerId: $IndiOwnerId
            IndiReplyPostid: $IndiReplyPostid
            IndiReplyCreatetimeB: $IndiReplyCreatetimeB
            IndiReplyContent: $IndiReplyContent
            IndiMStatus: $IndiMStatus
            IndiSocialAffixId: $IndiSocialAffixId
            IndiSocialReplyId: $IndiSocialReplyId
            IndiReplyId: $IndiReplyId
            IndiReplyCreatetimeA: $IndiReplyCreatetimeA
            IndiSocialId: $IndiSocialId
            Token: $Token
            Model: $Model
            IndiReplyTitle: $IndiReplyTitle
          ) {
            IndiMStatus
            IndiOwnerId
            IndiOwnerTruename
            IndiReplyContent
            IndiReplyCreatetime
            IndiReplyId
            IndiReplyPostid
            IndiSocialAffixId
            IndiSocialId
            IndiSocialName
            IndiSocialReplyId
            IndindiOwnerReplyTruename
            IndindiSocialReplyName
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        IndiOwnerId: data.IndiOwnerId,
        IndiReplyPostid: data.IndiReplyPostid,
        IndiReplyCreatetimeB: data.IndiReplyCreatetimeB,
        IndiReplyContent: data.IndiReplyContent,
        IndiMStatus: data.IndiMStatus,
        IndiSocialAffixId: data.IndiSocialAffixId,
        IndiSocialReplyId: data.IndiSocialReplyId,
        IndiReplyId: data.IndiReplyId,
        IndiReplyCreatetimeA: data.IndiReplyCreatetimeA,
        IndiSocialId: data.IndiSocialId,
        Token: data.Token,
        Model: data.Model,
        IndiReplyTitle:
          data.IndiReplyTitle === undefined ? '' : data.IndiReplyTitle,
      },
    })
    .catch(() => null)

  console.log('查询结果', res)

  if (res && res.data && Array.isArray(res.data.IndiReplyInquire)) {
    return res.data.IndiReplyInquire
  } else {
    return null
  }
}
