<template>
    <el-col 
      :xs="24" 
      :sm="24" 
      :md="12" 
      :lg="12"
      :xl="12"
    >
      <slot />
    </el-col>
</template>

<script>
export default {
  name: 'R31'
}
</script>

<style>

</style>