<template>
  <div class="pagination-com">
    <el-pagination
      prev-text="<上一页"
      next-text="下一页>"
      background
      layout="prev, pager, next"
      :total="500"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "ori-pagination",
};
</script>

<style lang="scss" scoped>
.pagination-com {
  // 定制翻页
  ::v-deep .el-pagination {
    &.is-background .btn-prev,
    &.is-background .btn-next,
    &.is-background .el-pager li {
      background-color: #f2f3f5;
      border: #ddd 1px solid;
      border-radius: 3px;
      padding: 0 11px;
      transition: 0.1s;
    }

    &.is-background .btn-prev:disabled,
    &.is-background .btn-next:disabled {
      color: #333;
    }

    &.is-background .el-pager li:hover,
    &.is-background .el-pager li:not(.disabled).active {
      color: #fff;
      background-color: red;
      border-color: red;
    }
  }
}
</style>