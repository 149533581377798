/**
 * 应用本地存储 sessionStorage
 */


export const BASE_URL = window.sessionStorage.getItem('BASE_URL') || ""

// Token
export const getToken = () => {
  let token = ''
  if (process.env.NODE_ENV === 'development') {
    token = "notoken_notoken"
  } else {
    token = window.sessionStorage.getItem('Token')
  }
  return token
}

// userInfo
export const getUserInfo = () => {
  const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || "{}")
  return userInfo
}