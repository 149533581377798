<template>
    <el-col 
      :xs="24" 
      :sm="12" 
      :md="12" 
      :lg="6"
      :xl="6"
    >
      <slot />
    </el-col>
</template>

<script>
export default {
  name: 'R41'
}
</script>

<style>

</style>