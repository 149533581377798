import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch((err) => err)
}

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
	{
		path: '*',
		redirect: '/ori-home',
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import('../views/Home/Home.vue'),
	},
	{
		path: '/hall',
		name: 'Hall',
		component: () => import('../views/Hall/Hall.vue'),
	},
	{
		path: '/publish',
		component: () => import('../views/Publish/Publish.vue'),
	},
	{
		path: '/databaseBackup',
		component: () => import('../views/databaseBackup/index.vue'),
	},
	{
		path: '/hypertext',
		component: () => import('../views/hypertext/index.vue'),
	},
	{
		path: '/ori-home',
		name: 'oriHome',
		component: () => import('../views/ori/HomePage/HomePage.vue'),
	},
	//home-page
	// {
	// 	path: '/ori-home-page',
	// 	name: 'oriHomePage',
	// 	component: () => import('../views/ori/HomePage/HomePage.vue'),
	// },
	{
		path: '/ori-search-detail',
		name: 'oriSearchDetail',
		component: () => import('../views/ori/HomePage/SearchDetail.vue'),
	},
	{
		path: '/ori-preview',
		name: 'oriPreview',
		component: () => import('../views/ori/HomePage/preview.vue'),
	},
	{
		path: '/ori-event',
		name: 'oriEvent',
		component: () => import('../views/ori/Event/Event.vue'),
	},

	{
		path: '/ori-person',
		name: 'oriPerson',
		component: () => import('../views/ori/Person/Person.vue'),
	},

	{
		path: '/person-detail',
		name: 'PersonDetail',
		component: () => import('../views/ori/Person/Detail.vue'),
	},

	{
		path: '/ori-enterprise',
		name: 'oriEnterprise',
		component: () => import('../views/ori/Enterprise/Enterprise.vue'),
	},

	{
		path: '/enterprise-detail',
		name: 'EnterpriseDetail',
		component: () => import('../views/ori/Enterprise/Detail.vue'),
	},

	{
		path: '/ori-invest',
		name: 'oriInvest',
		component: () => import('../views/ori/Invest/Invest.vue'),
	},

	{
		path: '/ori-policy',
		name: 'oriPolicy',
		component: () => import('../views/ori/Policy/Policy.vue'),
	},

	{
		path: '/ori-result',
		name: 'oriResult',
		component: () => import('../views/ori/Result/Result.vue'),
	},

	{
		path: '/ori-publish',
		name: 'oriPublish',
		component: () => import('../views/ori/Publish/Publish.vue'),
	},
	{
		path: '/ori-publish-detail',
		name: 'oriPublishDetail',
		component: () => import('../views/ori/Publish/detail.vue'),
	},

	{
		path: '/result-detail',
		name: 'ResultDetail',
		component: () => import('../views/ori/Result/Detail.vue'),
	},

	{
		path: '/auth',
		name: 'Auth',
		component: () => import('../views/ori/Auth/Auth.vue'),
	},

	{
		path: '/ori-mall',
		name: 'oriMall',
		component: () => import('../views/ori/Mall/Mall.vue'),
	},

	{
		path: '/Product',
		name: 'mallProduct',
		component: () => import('../views/ori/Mall/Product.vue'),
	},

	{
		path: '/category',
		name: 'mallCategory',
		component: () => import('../views/ori/Mall/category.vue'),
	},
	{
		path: '/userInfo',
		name: 'mallUserInfo',
		component: () => import('../views/ori/Mall/userInfo.vue'),
	},
	{
		path: '/shoppingCart',
		name: 'mallShoppingCart',
		component: () => import('../views/ori/Mall/shoppingCart.vue'),
	},
	{
		path: '/settlement',
		name: 'mallSettlement',
		component: () => import('../views/ori/Mall/settlement.vue'),
	},
	{
		path: '/checkstand',
		name: 'mallCheckstand',
		component: () => import('../views/ori/Mall/checkstand.vue'),
	},
	{
		path: '/myOrder',
		name: 'mallMyOrder',
		component: () => import('../views/ori/Mall/myOrder.vue'),
	},
	{
		path: '/checkOrderDetail',
		name: 'mallCheckOrderDetail',
		component: () => import('../views/ori/Mall/checkOrderDetail.vue'),
	},

	{
		path: '/ori-job',
		name: 'oriJob',
		redirect: '/ori-job/job',
		component: () => import('../views/ori/Job/Home.vue'),
		children: [
			{
				path: 'job',
				name: 'oriJobHome',
				component: () => import('../views/ori/Job/Job.vue'),
			},

			{
				path: 'manage',
				name: 'oriJobManage',
				component: () => import('../views/ori/Job/Manage.vue'),
			},

			{
				path: 'talent',
				name: 'oriJobTalent',
				component: () => import('../views/ori/Job/Talent.vue'),
			},

			{
				path: 'talent-search',
				name: 'oriJobTalentSearch',
				component: () => import('../views/ori/Job/TalentSearch.vue'),
			},

			{
				path: 'tool',
				name: 'oriJobTool',
				component: () => import('../views/ori/Job/Tool.vue'),
			},
		],
	},

	{
		path: '/ori-financing',
		redirect: '/ori-financing/home',
		name: 'oriFinancing',
		component: () => import('../views/ori/Financing/Financing.vue'),
		children: [
			{
				path: 'home',
				name: 'FinancingHome',
				component: () => import('../views/ori/Financing/Home.vue'),
			},

			{
				path: 'short-news',
				name: 'FinancingShortNews',
				component: () => import('../views/ori/Financing/ShortNews.vue'),
			},
			{
				path: 'newest',
				name: 'NewsNewest',
				component: () => import('../views/ori/Financing/Newest.vue'),
			},
			{
				path: 'recommended',
				name: 'NewsRecommended',
				component: () => import('../views/ori/Financing/Recommended.vue'),
			},
			{
				path: 'ventureCapital',
				name: 'NewsVentureCapital',
				component: () => import('../views/ori/Financing/VentureCapital.vue'),
			},
			{
				path: 'economics',
				name: 'NewsEconomics',
				component: () => import('../views/ori/Financing/Economics.vue'),
			},
			{
				path: 'car',
				name: 'NewsCar',
				component: () => import('../views/ori/Financing/Car.vue'),
			},
			{
				path: 'technology',
				name: 'NewsTechnology',
				component: () => import('../views/ori/Financing/Technology.vue'),
			},
			{
				path: 'companiesTake',
				name: 'NewsCompaniesTake',
				component: () => import('../views/ori/Financing/CompaniesTake.vue'),
			},
			{
				path: 'life',
				name: 'NewsLife',
				component: () => import('../views/ori/Financing/Life.vue'),
			},
			{
				path: 'innovation',
				name: 'NewsInnovation',
				component: () => import('../views/ori/Financing/Innovation.vue'),
			},
			{
				path: 'realEstate',
				name: 'NewsRealEstate',
				component: () => import('../views/ori/Financing/RealEstate.vue'),
			},
			{
				path: 'workplace',
				name: 'NewsWorkplace',
				component: () => import('../views/ori/Financing/Workplace.vue'),
			},
			{
				path: 'enterprise',
				name: 'NewsEnterprise',
				component: () => import('../views/ori/Financing/Enterprise.vue'),
			},
			{
				path: 'other',
				name: 'NewsOther',
				component: () => import('../views/ori/Financing/Other.vue'),
			},

			{
				path: 'live',
				name: 'FinancingLive',
				component: () => import('../views/ori/Financing/Live.vue'),
			},

			{
				path: 'video',
				name: 'FinancingVideo',
				component: () => import('../views/ori/Financing/Video.vue'),
			},

			{
				path: 'topic',
				name: 'FinancingTopic',
				component: () => import('../views/ori/Financing/Topic.vue'),
			},

			{
				path: 'activity',
				name: 'FinancingActivity',
				component: () => import('../views/ori/Financing/Activity.vue'),
			},
		],
	},

	// 个人中心
	{
		path: '/ori-cucenter',
		redirect: '/ori-cucenter/home',
		name: 'CuCenter',
		component: () => import('../views/ori/CuCenter/Center.vue'),
		children: [
			{
				path: 'home',
				name: 'CenterHome',
				component: () => import('../views/ori/CuCenter/Home.vue'),
			},

			{
				path: 'basic-form',
				name: 'BasicForm',
				component: () => import('../views/ori/CuCenter/BasicForm.vue'),
			},

			{
				path: 'security',
				name: 'Security',
				component: () => import('../views/ori/CuCenter/Security.vue'),
			},

			{
				path: 'invite',
				name: 'Invite',
				component: () => import('../views/ori/CuCenter/Invite.vue'),
			},

			{
				path: 'history',
				name: 'History',
				component: () => import('../views/ori/CuCenter/History.vue'),
			},

			{
				path: 'news',
				name: 'News',
				component: () => import('../views/ori/CuCenter/News.vue'),
			},

			{
				path: 'coupon',
				name: 'Coupon',
				component: () => import('../views/ori/CuCenter/Coupon.vue'),
			},

			{
				path: 'apply',
				name: 'Apply',
				component: () => import('../views/ori/CuCenter/Apply.vue'),
			},

			{
				path: 'project',
				name: 'Project',
				component: () => import('../views/ori/CuCenter/Project.vue'),
			},

			{
				path: 'resume',
				name: 'Resume',
				component: () => import('../views/ori/CuCenter/Resume.vue'),
			},

			{
				path: 'demand',
				name: 'Demand',
				component: () => import('../views/ori/CuCenter/Demand.vue'),
			},
			{
				path: 'demand-delivery',
				name: 'DemandDelivery',
				component: () => import('../views/ori/CuCenter/DemandDelivery.vue'),
			},
			{
				path: 'demand-proxy',
				name: 'DemandProxy',
				component: () => import('../views/ori/CuCenter/DemandProxy.vue'),
			},

			{
				path: 'demand-talent',
				name: 'DemandTalent',
				component: () => import('../views/ori/CuCenter/DemandTalent.vue'),
			},

			{
				path: 'inviting',
				name: 'Inviting',
				component: () => import('../views/ori/CuCenter/Inviting.vue'),
			},

			{
				path: 'order',
				name: 'Order',
				component: () => import('../views/ori/CuCenter/Order.vue'),
			},

			{
				path: 'wallet',
				name: 'Wallet',
				component: () => import('../views/ori/CuCenter/Wallet.vue'),
			},

			{
				path: 'wallet-inout',
				name: 'WalletInout',
				component: () => import('../views/ori/CuCenter/WalletInout.vue'),
			},

			{
				path: 'wallet-bank',
				name: 'WalletBank',
				component: () => import('../views/ori/CuCenter/WalletBank.vue'),
			},

			{
				path: 'coin',
				name: 'Coin',
				component: () => import('../views/ori/CuCenter/Coin.vue'),
			},

			{
				path: 'coin-share',
				name: 'CoinShare',
				component: () => import('../views/ori/CuCenter/CoinShare.vue'),
			},

			{
				path: 'bill',
				name: 'Bill',
				component: () => import('../views/ori/CuCenter/Bill.vue'),
			},

			{
				path: 'collection',
				name: 'Collection',
				component: () => import('../views/ori/CuCenter/Collection.vue'),
			},

			{
				path: 'comment',
				name: 'Comment',
				component: () => import('../views/ori/CuCenter/Comment.vue'),
			},
		],
	},
	//能耗管理平台
	{
		path: '/energy-home',
		name: 'energyHome',
		component: () => import('../views/energyManage/Home.vue'),
	},
	{
		path: '/keyInfo',
		name: 'energyKeyInfo',
		component: () => import('../views/energyManage/keyInfo.vue'),
	},
	{
		path: '/mstp-map',
		name: 'energyMstpMap',
		component: () => import('../views/energyManage/mstpMap.vue'),
	},
	{
		path: '/efficiencyAnalysis',
		name: 'energyEfficiencyAnalysis',
		component: () => import('../views/energyManage/efficiencyAnalysis.vue'),
	},
	{
		path: '/dianfei',
		name: 'energyDianfei',
		component: () => import('../views/energyManage/dianfei.vue'),
	},
	{
		path: '/deviceManager',
		name: 'energyDeviceManager',
		component: () => import('../views/energyManage/deviceManager.vue'),
	},
	{
		path: '/energy_consumption',
		name: 'energyEnergy_consumption',
		component: () => import('../views/energyManage/energy_consumption.vue'),
	},
	{
		path: '/userMng',
		name: 'energyUserMng',
		component: () => import('../views/energyManage/userMng.vue'),
	},
	{
		path: '/systemSettings',
		name: 'energySystemSettings',
		component: () => import('../views/energyManage/systemSettings.vue'),
	},
	{
		path: '/pumpRoomNav',
		name: 'energyPumpRoomNav',
		component: () => import('../views/energyManage/pumpRoomNav.vue'),
	},
	{
		path: '/estate',
		name: 'Estate',
		redirect: '/estate/community',
		component: () => import('@/views/energyManage/Estate/Estate.vue'),
		meta: {
			title: '资产管理',
		},
		children: [
			{
				path: '/estate/community',
				name: 'Community',
				component: () => import('@/views/energyManage/Estate/Community.vue'),
				meta: {
					title: '小区资产',
				},
			},
			{
				path: '/estate/pump-house',
				name: 'PumpHouse',
				component: () => import('@/views/energyManage/Estate/PumpHouse.vue'),
				meta: {
					title: '泵房资产',
				},
			},
			{
				path: '/estate/life-time',
				name: 'LifeTime',
				component: () => import('@/views/energyManage/Estate/LifeTime.vue'),
				meta: {
					title: '生命周期管理',
				},
			},
		],
	},
  {
    path: '/door',
    name: 'Door',
    redirect: '/door/ic-card',
    component: () => import('@/views/energyManage/Door/Door.vue'),
    meta: {
      title: '门禁管理'
    },
    children: [
      {
        path: '/door/ic-card',
        name: 'IcCard',
        component: () => import('@/views/energyManage/Door/IcCard.vue'),
        meta: {
          title: 'IC卡管理'
        }
      },
      {
        path: '/door/card-record',
        name: 'CardRecord',
        component: () => import('@/views/energyManage/Door/CardRecord.vue'),
        meta: {
          title: '门禁记录'
        }
      },
    ]
  },
  {
		path: '/EstateAny',
		name: 'energyEstateAny',
		component: () => import('../views/energyManage/Anylasis/EstateAny.vue'),
	},
]

const router = new VueRouter({
	routes,
})

export default router
