import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo } from '../session'
Vue.use(Vuex)

import userInfo from './userInfo'

export default new Vuex.Store({
	state: {
		// 公司id
		IndiSocialId: getUserInfo().IndiSocialId || '',

		// 登录这个工作人员的id
		IndiOwnerId: getUserInfo().IndiOwnerId || '',

		// 登录人的名字
		IndiTruename: getUserInfo().IndiTruename || '',

		// 这个用户的权限
		IndiJurisdiction: getUserInfo().IndiJurisdiction || [],
	},
	mutations: {},
	actions: {},
	modules: {
		userInfo,
	},
})
