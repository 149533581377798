import scrypt from 'scrypt-async-modern'

export default (pwd) => {
  return scrypt(pwd, 'saltysalt', {
    N: 16384,
    r: 8,
    p: 1,
    dkLen: 16,
    encoding: 'hex',
  })
}
