<template>
  <div class="option-row">
    <div class="title">
      <span v-if="title"><i class="el-icon-connection"></i>{{ title }}</span>
      <slot name="title" />
    </div>
    <div class="options">
      <template></template>

      <div class="option">
        <span class="option-item" v-for="i in options" :key="i.id">
          {{ i.label }}
        </span>
      </div>

      <div class="sub-option"></div>

      <span class="more-btn"> 更多> </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ori-option",
  data() {
    return {
      title: "活动类型：",
      options: [
        {
          id: "1",
          label: "创新创业大赛",
        },
        {
          id: "2",
          label: "创新人才",
        },
        {
          id: "3",
          label: "活动",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.option-row {
  width: 100%;
  padding: 10px 0 0;
  background: #fff;
  display: flex;
  align-items: flex-start;

  font-size: 12px;
  color: #333;

  .title {
    width: 90px;
    text-align: right;
    padding: 4px 0;
  }

  .options {
    height: 35px;
    width: 90%;
    position: relative;

    .option {
      .option-item {
        border: 1px solid #fff;
        border-radius: 3px;
        padding: 4px 10px;
        display: inline-block;
        cursor: pointer;
        margin: 0 5px;
      }
    }

    .more-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }
  }

  & + .option-row {
    border-top: 1px dashed #eee;
  }
}
</style>