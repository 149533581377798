import Vue from 'vue'

// import VueI18n from 'vue-i18n'
// import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// import enUS from 'vxe-table/lib/locale/lang/en-US'

// 按需引入 vxe-table
import XEUtils from 'xe-utils'
// import 'xe-utils'
// import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'

import {
  VXETable,
  Table,
  Column,
  Header,
  Footer,
  Filter,
  Edit,
  Menu,
  Export,
  Keyboard,
  Validator,
  Modal,
  Input,
  Select,
  Button,
  Icon,
  Tooltip,
  Pager,
} from 'vxe-table'

import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

VXETable.setup({
  // 使用 vue-i18n 解析占位符 '{xx}'
  //i18n: (key, args) => i18n.t(key, args),
  // 使用 xe-utils 解析占位符 '{xx}'
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

// 导出表格
// VXETable.use(VXETablePluginExportXLSX)

// 表格模块
Vue.use(Icon)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Filter)
Vue.use(Edit)
Vue.use(Menu)
Vue.use(Export)
Vue.use(Keyboard)
Vue.use(Validator)
Vue.use(Table)
Vue.use(Column)
Vue.use(Modal)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Pager)

Vue.prototype.$XPrint = VXETable.print
Vue.prototype.$XModal = VXETable.modal
